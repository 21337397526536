<template>
    <div class="commit-button">
        <button :style="{color: color, backgroundColor: backgroundColor}">{{text}}</button>
    </div>
</template>
<script>
export default {
    name: "CommitButton",
    props: ["color", "backgroundColor", "text"]
}
</script>
<style lang="less" scoped>
    .commit-button {
        text-align: center;

        button {
            min-width: 200px;
            height: 32px;
            border-radius: 4px;
            border: none;
            outline: none;
            font-size: 14px;
            line-height: 32px;
        }
    }
</style>